import React from 'react';
import './Home.css';

function Home() {
    return (
        <div className="TitleWrapper">
            <h1>Home</h1>
            <div className="Home">
                <div className="Home-message">
                    The Undergraduate Journal of Law and Politics is a group of writers, editors, and executives that
                    work
                    to draft and publish undergraduate students’ writings of law reviews, political interests, and
                    opinion
                    pieces. Unlike a traditional law review, the UJLP provides members with the opportunity to write on
                    both
                    law and politics, not just strictly court cases. We publish scholarly articles, essays, book
                    reviews,
                    and commentaries that explore the dynamic relationship between legal frameworks and political
                    processes.
                </div>
            </div>
        </div>
    );
}

export default Home;